
import Vue from 'vue'
import Introduction from '../components/Blog.vue'

export default Vue.extend({
  name: 'Blog-h',
  components: {
  Introduction
  },
  })
