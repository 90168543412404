import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Index from '../views/Index.vue'
import Blog from '../views/Blog.vue'
import Cv from '../views/Cv.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: Index
  },
  {
    path: '/blog',
    name: 'blog',
    component: Blog 
  },
  {
    path: '/alexander_mehks_cv.pdf',
    name: 'cv',
    component: Cv
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
