
import Vue from 'vue'

export default Vue.extend({
  name: 'About-me',
  components: {
  },

  data: function() {
      return {
          articles: 
          [
          {
          "url":"https://web.archive.org/web/20070613184827/http://yudkowsky.net/singularity.html",
          "description": "If computing speeds double every two years, what happens when computer-based AIs are doing the research?",
          "image": "singularity.jpg",
          "title": "Eliezer Yudkowsky on the Singularity"
          },
          {
          "url":"https://fasterthanli.me/articles/a-half-hour-to-learn-rust",
          "description": "In order to increase fluency in a programming language, one has to read a lot of it. But how can you read a lot of it if you don't know what it means? In this article, instead of focusing on one or two concepts, I'll try to go through as many Rust snippets as I can, and explain what the keywords and symbols they contain mean. Ready? Go!", 
          "image": "rustlogo.jpg",
          "title": "A half-hour to learn Crablang"
          },
          ]
      }

  },


  methods:{
      getImage(image){
          return require("../assets/"+image)
      }
  },


  mounted(){
      console.log("Are you a developer to? Hit me up :) ")
  }
  })
