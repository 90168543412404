
import Vue from 'vue'

export default Vue.extend({
  name: 'Cv-p',
  components: {
  },

  methods:{
  },

  mounted(){
      console.log("Are you a developer to? Hit me up :) ")
  }
  })
