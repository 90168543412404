
import Vue from 'vue'
import Introduction from '../components/About_me.vue'

export default Vue.extend({
  name: 'Home-h',
  components: {
  Introduction
  },
  })
