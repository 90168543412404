import { render, staticRenderFns } from "./Blog.vue?vue&type=template&id=8c249b12&scoped=true&"
import script from "./Blog.vue?vue&type=script&lang=ts&"
export * from "./Blog.vue?vue&type=script&lang=ts&"
import style0 from "./Blog.vue?vue&type=style&index=0&id=8c249b12&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c249b12",
  null
  
)

export default component.exports