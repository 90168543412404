
import Vue from 'vue'

export default Vue.extend({
  name: 'About-me',
  components: {
  },

  data: function() {
      return {
          coffees: 0,
      }

  },


  methods:{
  },


  mounted(){
      console.log("Are you a developer to? Hit me up :) ")
  }
  })
