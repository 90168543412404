
import Vue from 'vue'
import Introduction from '../components/Cv.vue'

export default Vue.extend({
  name: 'Cv-h',
  components: {
    Introduction
  },
  })
